import Script from 'next/script'

import { useIsCroatiaCountryCode } from '@hmn/rtl-web-core/context/CountryCode.context'
import { useEnvironment } from '@hmn/rtl-web-core/hooks'

function TaboolaScript() {
    const { thirdPartyEnabled } = useEnvironment()
    const isHR = useIsCroatiaCountryCode()
    if (!thirdPartyEnabled || isHR) {
        return null
    }
    return <Script src="//pahtzc.tech/c/www.net.hr.js" id="taboola-net" async />
}

export default TaboolaScript
